.hero-section {
    padding: 1.5rem;
    position: relative;
    .hero-inner {
        position: absolute;
        inset: 1.5rem;
        width: initial;
        @media only screen and (max-width: 1023px) {
            position: relative;
            inset: initial;
        }
    }
    .intro {
        max-width: 740px;
        width: 100%;
        color: white;
    }
    .video-wrapper {
        position: relative;
        width: 100%;
        height: calc(100svh - 3rem);
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 14px;
            background: linear-gradient(0deg, rgba(61, 65, 82, 0.60) 0%, rgba(61, 65, 82, 0.60) 100%);
        }
        video {
            border-radius: 14px;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        img {
            border-radius: 14px;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        @media only screen and (max-width: 1023px) {
            position: absolute;
            width: calc(100% - 32px);
            min-height: calc(80svh - 3rem);
        }
    }
    @media only screen and (max-width: 1023px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}